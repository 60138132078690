/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import ChartLegend from 'src/components/chartLegend';
import ChartWrapper from 'src/components/chartWrapper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'src/components/table';
import TooltipedText from 'src/components/tooltipedText';
import { coloredDatasets } from 'src/helpers/chartJsHelpers';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  table: {
    minWidth: 800,
    whiteSpace: 'nowrap',
  },
}));

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 5,
          padding: 20,
        },
        gridLines: {
          display: false,
        },
        barPercentage: 0.6,
        categoryPercentage: 0.6,
        afterFit: scaleInstance => {
          // eslint-disable-next-line no-param-reassign
          scaleInstance.width = 120; // sets the width to 120px
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          display: false,
          padding: 20,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    mode: 'nearest',
  },
};

export const OverviewLeadResultsPanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, legends, labels, dataSets } = data;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title4 {
                html
                text
                raw
              }
              tooltip4a {
                html
                text
                raw
              }
              tooltip4b {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const datasets = coloredDatasets('bar', {
    labels,
    datasets: dataSets.map(dataset => ({ ...dataset, data: [...dataset?.data] })),
  });

  const handleExpand = () => {
    onExpandToggle();
  };

  return (
    <>
      <Tab title={prismicData.title4.text} drag onClick={handleExpand} expanded={isExpanded}>
        {datasets.labels.length > 0 ? (
          <>
            <ChartLegend legend={legends} maxWidth={500} />

            <ChartWrapper>
              <HorizontalBar data={datasets} options={options} height={400} />
            </ChartWrapper>

            {datasets.tableData?.length > 0 && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {labels.map((label, idx) => (
                        <TableCell key={idx}>{label}</TableCell>
                      ))}
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2" align="right">
                          Press 1
                        </Typography>
                      </TableCell>
                      <TableCell>70%</TableCell>
                      <TableCell>70%</TableCell>
                      <TableCell>70%</TableCell>
                      <TableCell>70%</TableCell>
                      <TableCell>70%</TableCell>
                      <TableCell>70%</TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <TooltipedText tooltipText={prismicData.tooltip4a.text} position="flex-end">
                          <Typography variant="body2">Connections</Typography>
                        </TooltipedText>
                      </TableCell>
                      <TableCell>
                        70%
                        <Typography variant="h6" style={{ opacity: 0.5 }}>
                          20
                        </Typography>
                      </TableCell>
                      <TableCell>
                        70%
                        <Typography variant="h6" style={{ opacity: 0.5 }}>
                          20
                        </Typography>
                      </TableCell>
                      <TableCell>
                        70%
                        <Typography variant="h6" style={{ opacity: 0.5 }}>
                          20
                        </Typography>
                      </TableCell>
                      <TableCell>
                        70%
                        <Typography variant="h6" style={{ opacity: 0.5 }}>
                          20
                        </Typography>
                      </TableCell>
                      <TableCell>
                        70%
                        <Typography variant="h6" style={{ opacity: 0.5 }}>
                          20
                        </Typography>
                      </TableCell>
                      <TableCell>
                        70%
                        <Typography variant="h6" style={{ opacity: 0.5 }}>
                          20
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <TooltipedText tooltipText={prismicData?.tooltip4b?.text} position="flex-end">
                          <Typography variant="body2" align="right">
                            Missed Opportunities
                          </Typography>
                        </TooltipedText>
                      </TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>10</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <Typography align="center" variant="body1">
            There is no data for selected date range
          </Typography>
        )}
      </Tab>
    </>
  );
};
