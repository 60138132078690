/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { OverviewSourceOfLeadsPanel } from './OverviewSourceOfLeadsPanel';

import { getSources, getSourcesIsLoading } from '../../state/selectors';
import { useFetchSources, useSourcesActions } from '../../state/hooks';

export const OverviewSourceOfLeads = () => {
  const data = useSelector(getSources);
  const isLoading = useSelector(getSourcesIsLoading);
  const { toggleSourcesPanel } = useSourcesActions();

  useFetchSources();

  return (
    <LoadingContainer loading={isLoading}>
      <OverviewSourceOfLeadsPanel data={data} onExpandToggle={toggleSourcesPanel} />
    </LoadingContainer>
  );
};
