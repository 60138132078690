/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { OverviewPerformancePanel } from './OverviewPerformancePanel';

import OpenMoc from 'src/features/openPrc';

import { getPerformance, getPerformanceIsLoading } from '../../state/selectors';
import { useFetchPerformance, usePerformanceActions } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
  openMocWrapper: {
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export const OverviewPerformance = () => {
  const classes = useStyles();

  const data = useSelector(getPerformance);

  const isLoading = useSelector(getPerformanceIsLoading);

  const { togglePerformancePanel } = usePerformanceActions();

  useFetchPerformance();

  return (
    <>
      <LoadingContainer loading={isLoading}>
        <OverviewPerformancePanel data={data} onExpandToggle={togglePerformancePanel} />
      </LoadingContainer>
      <div className={classes.openMocWrapper}>
        <OpenMoc pageType="overview" />
      </div>
    </>
  );
};
