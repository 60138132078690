import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPage,
  getGroupId,
  getReportingDateRange,
} from './selectors';
import { actions } from './slice';

export const useReportingData = () => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(getReportingDateRange);

  const setReportingDate = useCallback(dateRange => dispatch(actions.setReportingDate(dateRange)), [dispatch]);

  return {
    from,
    to,

    setReportingDate,
  };
};

export const useFetchPerformance = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getPerformance({ date, groupId }));
  }, [dispatch, date, groupId]);
};
