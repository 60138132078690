/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import formatISO from 'date-fns/formatISO9075';
import addSeconds from 'date-fns/addSeconds';

import Paper from 'src/components/paper';
import TooltipedText from 'src/components/tooltipedText';
import DateRangePicker from 'src/components/dateRangePicker';

import { useReportingData } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    '&.MuiGrid-container > .MuiGrid-item': {
      width: 240
    },
    '&.MuiGrid-container > .MuiGrid-item:nth-child(even)': {
      paddingLeft: 0,
      paddingRight: 40
    }
  },
  paperStyle: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.secondary.main,
      color: '#ffffff'
    }
  },
}));

export const ReportingDatePicker = () => {
  const classes = useStyles();
  const { from, to, setReportingDate } = useReportingData();

  const handleDateRangeChange = ({ from, to }) => {
    // const date = {
    //   from: new Date(from).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace(' ', 'T').replace('−', '-'),
    //   to: addSeconds(new Date(to), 1).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace('−', '-').replace(' ', 'T')
    // };
    // setReportingDate(date);
    // setReportingDate({ from: formatISO(from), to: formatISO(to) });
    setReportingDate({ from, to });
  };

  return (
    <Paper className={classes.paperStyle}>
      <div className={classes.header}>
        <TooltipedText tooltipText="Applies to entire section below" tooltipStyle={{ backgroundColor: '#ffffff', color: '#202239' }}>
          <Typography variant="h6">Dashboard Reports Date Range</Typography>
        </TooltipedText>
      </div>
      <Grid container spacing={5} className={classes.gridContainer}>
        <Grid item>
          {/* <div>
            <Typography variant="overline" gutterBottom>
              Date
            </Typography>
            <div style={{ marginTop: 4 }}> */}
          <DateRangePicker onChange={handleDateRangeChange} from={from} to={to} />
          {/* </div>
          </div> */}
        </Grid>
      </Grid>
    </Paper>
  );
};
