export const getPage = state => state.overview;

export const getLoading = state => state.overview.loading;

export const getError = state => state.overview.error;

export const getFilters = state => state.overview.filters;

export const getFiltersIsExpanded = state => state.overview.filters.isExpanded;

export const getFiltersLead = state => state.overview.filtersLead;

export const getLeadFiltersIsExpanded = state => state.overview.filtersLead.isExpanded;

export const getFiltersStatuses = state => state.overview.filters.statuses;

export const getAllFiltersStatuses = state => state.overview.filters.allStatuses;

export const getReportingDateRange = state => state.overview.date;

export const getAvgSpeedDateRange = state => state.overview.avg.date;

export const getPerformance = state => state.overview.performance;

export const getGroupId = state => state.global.groupList.selectedGroup;

export const getGroupListData = state => state.global.groupList.data;

export const getPerformanceIsLoading = state => state.overview.performance.isLoading;

export const getSources = state => state.overview.sources;

export const getSourcesIsLoading = state => state.overview.sources.isLoading;

export const getResults = state => state.overview.results;

export const getResultsIsLoading = state => state.overview.results.isLoading;

export const getCallActivities = state => state.overview.callActivities;

export const getCallActivitiesIsLoading = state => state.overview.callActivities.isLoading;

export const getUpcomingCalls = state => state.overview.upcomingCalls;

export const getUpcomingCallsIsLoading = state => state.overview.upcomingCalls.isLoading;

export const getAvgSpeed = state => state.overview.avg;

export const getAvgSpeedIsLoading = state => state.overview.avg.isLoading;

export const getResponse = state => state.overview.response;

export const getResponseIsLoading = state => state.overview.response.isLoading;

export const getParkedCalls = state => state.overview.parked;

export const getParkedCallsIsLoading = state => state.overview.parked.isLoading;

export const getParkedCallsIsLoaded = state => state.overview.parked.loaded;

export const getActiveCalls = state => state.overview.active;

export const getActiveCallsIsLoading = state => state.overview.active.isLoading;

export const getActiveCallsIsLoaded = state => state.overview.active.loaded;

export const getFirstAttemptPerformance = state => state.insights.first;

export const getFirstAttemptPerformanceIsLoading = state => state.insights.first.isLoading;
