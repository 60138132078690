/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { makeStyles, Grid, FormControl, Button, Typography } from '@material-ui/core';
import {
  startOfDay
} from 'date-fns';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import DateRangePicker from 'src/components/dateRangePicker';
import Tab from 'src/components/tab';

import XlsIcon from 'src/assets/icons/xls.svg';
import CsvIcon from 'src/assets/icons/csv.svg';

import { useOverviewFiltersData } from '../../state/hooks';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkboxMargin: {
    '& .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary ': {
      marginLeft: '-12px',
    }
  },
  gridContainer: {
    '&.MuiGrid-container > .MuiGrid-item': {
      width: 240
    },
    '&.MuiGrid-container > .MuiGrid-item:nth-child(even)': {
      paddingLeft: 0,
      paddingRight: 40
    }
  }
}));

export const LeadDataFilters = () => {
  const classes = useStyles();
  const {
    filtersLead,
    isLeadExpanded,

    toggleLeadFilterPanel,
    setFilterLeadDate,
    getOverviewLeadData,
  } = useOverviewFiltersData();

  const handleExpand = () => {
    toggleLeadFilterPanel();
  };

  const handleDateChange = ({ from, to }) => {
    const date = {
      from: new Date(from).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace(' ', 'T').replace('−', '-'),
      to: startOfDay(new Date(to)).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace('−', '-').replace(' ', 'T')
    };

    setFilterLeadDate(date);
  };

  const handleSubmitDownload = () => {
    getOverviewLeadData();
  };

  return (

    <LoadingContainer loading={filtersLead.isLoading} height={'auto'}>
      <Tab title="Lead Data Report" onClick={handleExpand} expanded={isLeadExpanded}
        tooltipedText="Downloadable detail report for all posted leads">
        <Grid container spacing={5} className={classes.gridContainer}>
          <Grid item>
            <div>
              <FormControl variant="outlined" color="secondary" size="small" margin="dense" fullWidth>
                <Typography variant="overline" gutterBottom>
                  Date
                </Typography>

                <DateRangePicker onChange={handleDateChange} />
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <div className={classes.buttonWrapper}>
          <Button
            // startIcon={<XlsIcon />}
            startIcon={<CsvIcon style={{ width: '22px', height: '22px' }} />}
            color="primary"
            variant="contained"
            size="large"
            disableElevation
            onClick={handleSubmitDownload}
          >
            Download Report
          </Button>
        </div>
      </Tab>
    </LoadingContainer>
  );
};
