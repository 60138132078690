const _posY = (elm) => {
  var test = elm, top = 0;

  while (!!test && test.tagName.toLowerCase() !== "body") {
    top += test.offsetTop;
    test = test.offsetParent;
  }

  return top;
}

const _viewPortHeight = () => {
  var de = document.documentElement;

  if (!!window.innerWidth) { return window.innerHeight; }
  else if (de && !isNaN(de.clientHeight)) { return de.clientHeight; }

  return 0;
}

const _scrollY = () => {
  if (window.pageYOffset) { return window.pageYOffset; }
  return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
}

export const isElementVisible = (elmName) => {
  if (!document.getElementById(elmName)) {
    return;
  }
  const vpH = _viewPortHeight(), // Viewport Height
    st = _scrollY(), // Scroll Top
    y = _posY(document.getElementById(elmName));

  return (y < (vpH + st));
}