/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, TableContainer, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';

const useStyles = makeStyles((theme) => ({
  tableBody: {
    '& *': {
      borderWidth: 0,
    },
    '& > *:nth-child(odd) > *': {
      backgroundColor: theme.palette.background.default
    },
  },
  container: {
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const OverviewSourceOfLeadsPanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, list } = data;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title3 {
                html
                text
                raw
              }
              titleTooltip3 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const handleExpand = () => {
    onExpandToggle();
  };

  return (
    <Tab
      title={prismicData.title3.text}
      tooltipedText={prismicData.titleTooltip3.text}
      onClick={handleExpand}
      expanded={isExpanded}
      drag
    >
      {list.length > 0 ? (
        <TableContainer>
          <Table>
            <TableBody className={classes.tableBody}>
              {list.map(({ source, connected, tagged }, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    <Typography>{source ? source : 'Source not posted'}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{connected}%</b> Connection Rate
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="right">
                    <Typography>
                      <b>{tagged}%</b> call tagged
                    </Typography>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
    </Tab>
  );
};
