import React from 'react';
import { Hidden } from '@material-ui/core';

import SEO from 'src/components/seo';
import GridLayout from 'src/components/draggableGrid';

// import PerformanceTab from 'src/features/overview/performance';
import NewOpportunity from 'src/features/overview/newOpportunity';
// import FilterBy from 'src/features/overview/filterBy';
// import SourceOfLeads from 'src/features/overview/sourceOfLeads';
// import LeadResults from 'src/features/overview/leadResults';
// import CallActivity from 'src/features/overview/callActivity';
// import UpcomingCalls from 'src/features/overview/upcomingCalls';
// import Recommendation from 'src/features/overview/recommendation';
// import AvgSpeed from 'src/features/overview/avgSpeed';
// import LeadResponse from 'src/features/overview/leadResponse';
// import ParkedCalls from 'src/features/overview/parkedCalls';
// import ActiveCalls from 'src/features/overview/activeCalls';
// import MasterDatePicker from 'src/features/overview/masterDatePicker';
import {
  OverviewDataFilters,
  LeadDataFilters,
  OverviewPerformance,
  ReportingDatePicker,
  OverviewSourceOfLeads,
  OverviewLeadResults,
  // OverviewCallActivity,
  OverviewUpcomingCalls,
  OverviewRecommendation,
  OverviewAvgSpeed,
  OverviewLeadResponse,
  OverviewParkedCalls,
  OverviewActiveCalls,
} from 'src/features/overview';
import { GroupDataFilters } from 'src/features/groupPerformance';

export default function Overview() {
  return (
    <>
      <SEO title="Overview" />

      <Hidden smDown>
        <NewOpportunity />

      </Hidden>

      <OverviewDataFilters />

      <LeadDataFilters />

      <GroupDataFilters />

      <ReportingDatePicker />

      <GridLayout>
        <OverviewPerformance />

        <OverviewAvgSpeed />

        <OverviewSourceOfLeads />

        {/* <OverviewLeadResults /> */}

        {/* <OverviewCallActivity /> */}

        {/* <OverviewUpcomingCalls /> */}

        <OverviewRecommendation />

        {/* <OverviewLeadResponse /> */}

        <OverviewParkedCalls />

        <OverviewActiveCalls />
      </GridLayout>

      {/* <Hidden smDown>
        <NewOpportunity />
        <FilterBy />
      </Hidden>

      <MasterDatePicker />

      <GridLayout>
        <PerformanceTab />
        <SourceOfLeads />
        <LeadResults />
        <CallActivity />
        <UpcomingCalls />
        <Recommendation />
        <AvgSpeed />
        <LeadResponse />
        <ParkedCalls />
        <ActiveCalls />
      </GridLayout> */}
    </>
  );
}
