/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Grid, FormControl, Button, Select, MenuItem, Typography } from '@material-ui/core';
// import addSeconds from 'date-fns/addSeconds';
import {
  startOfDay
} from 'date-fns';

import Checkbox from 'src/components/checkbox';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import DateRangePicker from 'src/components/dateRangePicker';
import Tab from 'src/components/tab';

import XlsIcon from 'src/assets/icons/xls.svg';
import CsvIcon from 'src/assets/icons/csv.svg';

import { useOverviewFiltersData } from '../../state/hooks';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkboxMargin: {
    '& .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary ': {
      marginLeft: '-12px',
    }
  },
  gridContainer: {
    '&.MuiGrid-container > .MuiGrid-item': {
      width: 240
    },
    '&.MuiGrid-container > .MuiGrid-item:nth-child(even)': {
      paddingLeft: 0,
      paddingRight: 40
    }
  }
}));

export const OverviewDataFilters = () => {
  const classes = useStyles();
  const {
    isExpanded,
    allStatuses,
    statuses,
    filters,
    allGroups,

    toggleFilterPanel,
    toggleStatuses,
    toggleStatus,
    setFilterDate,
    setFilterGroup,
    getOverviewData,
  } = useOverviewFiltersData();

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title1 {
                html
                text
                raw
              }
              tooltip1a {
                html
                text
                raw
              }
              tooltip1b {
                html
                text
                raw
              }
              tooltip1c {
                html
                text
                raw
              }
              tooltip1d {
                html
                text
                raw
              }
              tooltip1e {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const handleExpand = () => {
    toggleFilterPanel();
  };

  const handleToggleStatus = ({ target }) => {
    const { name: id } = target;

    toggleStatus(id);
  };

  const handleDateChange = ({ from, to }) => {
    const date = {
      from: new Date(from).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace(' ', 'T').replace('−', '-'),
      to: startOfDay(new Date(to)).toLocaleString('sv', { timeZoneName: 'longOffset' }).replace(' GMT', '').replace('−', '-').replace(' ', 'T')
    };

    setFilterDate(date);
  };

  const handleGroupChange = e => {
    const { value: id } = e.target;

    setFilterGroup(id);
  };

  const handleSubmitDownload = () => {
    getOverviewData();
  };

  return (
    <LoadingContainer loading={filters.isLoading} height={'auto'}>
      <Tab title="Call Data Report" onClick={handleExpand} expanded={isExpanded} tooltipedText="Downloadable detail report for all calls">
        <Grid container spacing={5} className={classes.gridContainer} >
          <Grid item>
            <FormControl variant="outlined" color="secondary" size="small" margin="dense" fullWidth>
              <Typography variant="overline" gutterBottom>
                Date
              </Typography>

              <DateRangePicker onChange={handleDateChange} />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" color="secondary" size="small" margin="dense" fullWidth>
              <Typography variant="overline" gutterBottom>
                Group
              </Typography>

              <Select defaultValue="0" onChange={handleGroupChange}>
                <MenuItem value={0}>All Groups</MenuItem>

                {
                  allGroups.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      id={id}
                    >
                      {name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={9}>
            <div>
              <Typography variant="overline">Report Filters</Typography>
              <Grid container spacing={2}>
                <Grid item xs={1} className={classes.checkboxMargin}>
                  <Checkbox label="All" id="all" checked={allStatuses} onChange={toggleStatuses} />
                </Grid>

                <Grid item xs="auto">
                  <Checkbox
                    label="Press 3"
                    id="86d"
                    checked={statuses['86d']}
                    onChange={handleToggleStatus}
                    tooltipText={prismicData.tooltip1a.text}
                  />
                </Grid>

                <Grid item xs="auto">
                  <Checkbox
                    label="Connected"
                    id="connected"
                    checked={statuses.connected}
                    onChange={handleToggleStatus}
                    tooltipText={prismicData.tooltip1b.text}
                  />
                </Grid>

                <Grid item xs="auto">
                  <Checkbox
                    label="Not Connected"
                    id="notConnected"
                    checked={statuses.notConnected}
                    onChange={handleToggleStatus}
                    tooltipText={prismicData.tooltip1c.text}
                  />
                </Grid>

                <Grid item xs="auto">
                  <Checkbox
                    label="No Answer"
                    id="noAnswer"
                    checked={statuses.noAnswer}
                    onChange={handleToggleStatus}
                    tooltipText={prismicData.tooltip1d.text}
                  />
                </Grid>
              </Grid>
              <FormGroup row>
                <Grid item xs={1} />

                <Grid item xs={3}>
                  <Checkbox
                    label="Tagged Yes"
                    id="taggedYes"
                    checked={statuses.taggedYes}
                    onChange={handleToggleStatus}
                    tooltipText={prismicData.tooltip1e.text}
                  />
                </Grid>

                <Grid item>
                  <Checkbox label="Tagged No" id="taggedNo" checked={statuses.taggedNo} onChange={handleToggleStatus} />
                </Grid>
              </FormGroup>
            </div>
          </Grid> */}
        </Grid>

        <div className={classes.buttonWrapper} >
          <Button
            // startIcon={<XlsIcon />}
            startIcon={<CsvIcon style={{ width: '22px', height: '22px' }} />}
            color="primary"
            variant="contained"
            size="large"
            disableElevation
            onClick={handleSubmitDownload}
          >
            Download Report
          </Button>
        </div>
      </Tab>
    </LoadingContainer>
  );
};
