/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FormControl, makeStyles, Typography, Grid, Select, MenuItem } from '@material-ui/core';

import StopwatchIcon from 'src/assets/icons/stopwatch.svg';
import stopwatch from 'src/images/stopwatch.gif';

import { Line } from 'react-chartjs-2';
import {
  format,
  parse,
  subDays,
  startOfDay,
  endOfDay,
  subMonths,
} from 'date-fns';
import formatISO from 'date-fns/formatISO9075';

import { coloredDatasets } from 'src/helpers/chartJsHelpers';
import { formattedTime } from 'src/helpers/time';

import Tab from 'src/components/tab';
import ChartWrapper from 'src/components/chartWrapper';
import CircleCounter from 'src/components/circleCounter';

import { useAvgSpeedData } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 125,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
    },
  },
  textWrapper: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  doughnutChartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    // maxWidth: 215,
    margin: 'auto',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  doughnutChartWrapperText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: 25,
  },
}));

const dates = [
  {
    id: 0,
    label: 'Daily',
    value: { from: startOfDay(new Date()), to: endOfDay(new Date()) }
  },
  {
    id: 1,
    label: 'Weekly',
    value: { from: startOfDay(subDays(new Date(), 7)), to: startOfDay(subDays(new Date(), 1)) }
  },
  {
    id: 2,
    label: 'Monthly',
    value: { from: startOfDay(subMonths(new Date(), 1)), to: startOfDay(subDays(new Date(), 1)) }
  },
];

const lineChartOptions = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
          padding: 20,
          callback: (value, index, values) => {
            return (value >= 60) ? Math.floor(value / 60) + ':' + (value % 60 === 0 ? '00' : value % 60) + ' s' : value + ' s';
          },
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        type: 'time',
        // distribution: 'series',
        stacked: false,
        bounds: 'ticks',
        time: {
          unit: 'day',
          tooltipFormat: 'DD. MMM',
          displayFormats: {
            day: 'DD. MMM yy',
          },
        },
        ticks: {
          padding: 20,
          // source: 'labels',
          beginAtZero: true,
          fontColor: '#202239',
          callback: value => {
            const date = parse(value, 'dd. MMM y', new Date());
            const weekDay = format(date, 'EEE');
            const displayValue = format(date, 'dd. MMM');

            return [weekDay, displayValue];
          },
        },
        gridLines: {
          display: false,
          // offsetGridLines: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      labelColor: (tooltipItem, chart) => {
        const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
        return {
          backgroundColor: dataset.borderColor,
        };
      },
      label: (tooltipItem, data) => {
        return (tooltipItem.yLabel >= 60) ? Math.floor(tooltipItem.yLabel / 60) + ':' + (tooltipItem.yLabel % 60 === 0 ? '00' : tooltipItem.yLabel % 60) + ' s' : tooltipItem.yLabel + ' s';
      },
    },
  },
};

export const OverviewAvgSpeedPanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();
  const prismicContent = useStaticQuery(
    graphql`query {
      allPrismicOverview {
        edges {
          node {
            data {
              title7 {
                html
                text
                raw
              }
              titleTooltip7 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }`
  );
  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;
  const { isExpanded, secondaryDatasets, secondaryDatasetsNeedleValue, dataSets } = data;
  const doughnutChartData = { datasets: JSON.parse(JSON.stringify(secondaryDatasets)) };
  const lineChartData = coloredDatasets('line', { datasets: JSON.parse(JSON.stringify(dataSets)) }, 'reverse');

  const { setAvgSpeedDate } = useAvgSpeedData();
  const [periodLable, setPeriodLable] = useState();

  const handleExpand = () => {
    onExpandToggle();
  };

  const handleDateRangeChange = (event) => {
    setPeriodLable(dates[event.target.value].label);
    setAvgSpeedDate({ from: formatISO(dates[event.target.value].value.from), to: formatISO(dates[event.target.value].value.to) });
  };

  useEffect(() => {
    setPeriodLable(dates[1].label);
    setAvgSpeedDate({ from: formatISO(dates[1].value.from), to: formatISO(dates[1].value.to) });
  }, []);

  return (
    <Tab
      title={prismicData.title7.text}
      tooltipedText={prismicData.titleTooltip7.text}
      tooltipedTextStyle={{ maxWidth: 320 }}
      onClick={handleExpand}
      expanded={isExpanded}
      drag
    >
      <div style={{ maxWidth: '229px' }} >
        <FormControl variant="outlined" color="secondary" size="small" margin="dense" fullWidth>
          <Select defaultValue='1' onChange={handleDateRangeChange}>
            {
              dates.map((date) => (
                <MenuItem
                  selected={date.id === 1 ? true : false}
                  key={date.id}
                  value={date.id}
                  name={date.value}
                  id={date.id}
                >
                  {date.label}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>

      <Grid container>
        <Grid item xs={12} md={3}>
          <div className={classes.doughnutChartWrapper}>
            <img style={{ width: '93px' }} src={stopwatch} />
            {/* <StopwatchIcon /> */}
            {/* <div style={{ display: 'flex' }}>
              {(secondaryDatasetsNeedleValue >= 60) &&
                <div className={classes.doughnutChartWrapperText}>
                  <CircleCounter counter={Math.floor(secondaryDatasetsNeedleValue / 60)} />
                  <Typography variant="button" align="center">
                    Minutes
                  </Typography>
                </div>
              }
              <div className={classes.doughnutChartWrapperText}>
                <CircleCounter counter={secondaryDatasetsNeedleValue % 60} />
                <Typography variant="button" align="center">
                  Seconds
                </Typography>
              </div>
            </div> */}

            {
              doughnutChartData?.datasets?.[0]?.data?.length > 0 && lineChartData?.datasets?.[0]?.data?.length > 0
                ? (<>
                    <Typography variant="h5" align="center" className={classes.text}>
                      {formattedTime(secondaryDatasetsNeedleValue)}
                    </Typography>
                    <Typography variant="overline" align="center" className={classes.text}>
                      Overall {periodLable} Average
                    </Typography>
                  </>)
                : (<></>)
            }
          </div>
        </Grid>

        <Grid item xs={12} md={9}>
          {/*
            <div className={classes.textWrapper}>
              <Typography align="center">
                {periodLable} Avg: {formattedTime(secondaryDatasetsNeedleValue)} Seconds
              </Typography>
            </div>
          */}
          {
            doughnutChartData?.datasets?.[0]?.data?.length > 0 && lineChartData?.datasets?.[0]?.data?.length > 0
              ? (<ChartWrapper>
                  <Line options={lineChartOptions} data={lineChartData} height={300} />
                </ChartWrapper>)
              : (<Typography align="center" variant="body1">
                  There is no data for selected date range
                </Typography>)
          }
        </Grid>
      </Grid>

    </Tab>
  );
};
