/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { OverviewActiveCallsPanel } from './OverviewActiveCallsPanel';

import { getActiveCalls, getActiveCallsIsLoading, getActiveCallsIsLoaded } from '../../state/selectors';
import { useActiveCallsActions, useFetchActiveCalls } from '../../state/hooks';

export const OverviewActiveCalls = () => {
  const data = useSelector(getActiveCalls);
  const isLoading = useSelector(getActiveCallsIsLoading);
  const isLoaded = useSelector(getActiveCallsIsLoaded);
  const { toggleActiveCallsPanel } = useActiveCallsActions();
  const isMounted = useRef(false);
  const elId = 'OverviewActiveCallsPanel';

  useFetchActiveCalls(isMounted, elId);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  return (
    <>
      <div id={elId}></div>
      <LoadingContainer loading={isLoading && !isLoaded}>
        <OverviewActiveCallsPanel data={data} onExpandToggle={toggleActiveCallsPanel} />
      </LoadingContainer>
    </>
  );
};
