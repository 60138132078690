/* eslint-disable react/prop-types */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default function StepsImage(props) {
  const { name } = props;
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "overview1.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "overview2.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data[name].childImageSharp.fluid} {...props} />;
}
