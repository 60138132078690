export const getPage = state => state.openPrcSlice;

export const getPerformance = state => state.openPrcSlice.performance;

export const getPerformanceIsLoading = state => state.openPrcSlice.performance.isLoading;

export const getReportingDateRange = state => state.openPrcSlice.date;

export const getGroupId = state => state.global.groupList.selectedGroup;

export const getDateOverview = state => state.overview.date;

export const getDateGroup = state => state.group.date;

export const getDateInsights = state => state.insights.date;
