import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: '50px SevenSegmentRegular'
  },
}));

const CircleCounter = (counter) => {
  const classes = useStyles();
  const canvas = useRef(null);
  const [initComp, setInitComp] = useState(false);

  useEffect(() => {
    initCanvas(counter)
  }, [counter]);
  useEffect(() => {
    setInitComp(true)
  }, []);

  const initCanvas = (counter) => {
    const formatedCounter = parseFloat(counter.counter.toFixed(0));
    const ctx = canvas?.current?.getContext("2d");
    let radius = canvas?.current?.height / 2;
    if (!initComp) {
      ctx.translate(radius, radius);
    }
    radius = radius * 0.95;
    ctx.clearRect(-75, -75, 150, 150);
    drawClock(ctx, radius, formatedCounter);
  }

  const drawClock = (ctx, radius, counter) => {
    drawSteps(ctx, radius, counter);
    fillText(ctx, counter, 50, "rgba(0,0,0,0.8)");
  }

  const fillText = (ctx, counter, fontSize, color) => {
    ctx.fillStyle = color;
    ctx.font = fontSize + "px SevenSegmentRegular";
    ctx.textAlign = "center";
    ctx.fillText(counter, 0, Math.round(fontSize / 4, 10));
  }

  const drawSteps = (ctx, radius, seconds) => {
    for (let num = 0; num < seconds; num++) {
      let ang = num * Math.PI / 30;
      ctx.rotate(ang);
      ctx.translate(0, -radius * 0.85);
      ctx.rotate(-ang);
      drawStep(ctx, ang, 10, 3, "rgba(0,0,0,0.3)");
      ctx.rotate(ang);
      ctx.translate(0, radius * 0.85);
      ctx.rotate(-ang);
    }
  }

  const drawStep = (ctx, ang, length, width, color) => {
    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.lineCap = "round";
    ctx.moveTo(0, 0);
    ctx.rotate(ang);
    ctx.moveTo(0, 0);
    ctx.lineTo(0, length);
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.rotate(-ang);
  }

  return (
    <canvas ref={canvas} width="150" height="150"></canvas>
  );
};

export default CircleCounter;