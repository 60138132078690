/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Line } from 'react-chartjs-2';
import { makeStyles, styled, Box, Typography } from '@material-ui/core';

import { SpanRound } from 'src/components/chartLegend';
import ChartWrapper from 'src/components/chartWrapper';
import Tab from 'src/components/tab';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'src/components/table';
import { coloredDatasets, coloredLegend } from 'src/helpers/chartJsHelpers';

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    // width: 780,
    margin: 'auto',
    '& > tbody > *:nth-child(odd) > *': {
      backgroundColor: theme.palette.background.default
    },
    '& > tbody th *': {
      fontWeight: '400 !important',
    },
    '& > tbody *': {
      fontWeight: '700',
    },
    '& thead *': {
      borderBottom: '1px solid #BBBCC3',
      fontWeight: '700',
    },
    '& tr > *:not(:last-child)': {
      opacity: 1,
      border: 'none',
      borderRight: 'none',
    },
    '& tr > *:last-child': {
      opacity: 1,
      border: 'none',
      borderRight: 'none',
    },
    '& th': {
      opacity: 1,
      border: 'none',
      borderRight: 'none',
    },
    '& td': {
      fontSize: 18,
    },

  },
  thWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 20,
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        ticks: {
          // beginAtZero: true,
          padding: 20,
          fontColor: '#202239',
        },
        gridLines: {
          // display: false,
          borderDash: [8, 4],
          drawTicks: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      labelColor: (tooltipItem, chart) => {
        const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
        return {
          backgroundColor: dataset.borderColor,
        };
      },
    },
  },
};

export const OverviewUpcomingCallsPanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, legends, labels, dataSets, tableSets } = data;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title6 {
                html
                text
                raw
              }
              titleTooltip6 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const datasets = coloredDatasets('line', {
    labels,
    datasets: JSON.parse(JSON.stringify(dataSets)),
  }, 'reverse');

  const colors = coloredLegend(legends, 'reverse');

  const handleExpand = () => {
    onExpandToggle();
  };

  return (
    <Tab
      title={prismicData.title6.text}
      tooltipedText={prismicData.titleTooltip6.text}
      tooltipedTextStyle={{ maxWidth: 368 }}
      onClick={handleExpand}
      expanded={isExpanded}
      drag
    >
      {datasets.labels.length > 0 ? (
        <>
          {/* <ChartLegend legend={legends} maxWidth={300} /> */}

          <ChartWrapper>
            <Line options={options} data={datasets} height={300} />
          </ChartWrapper>

          <TableContainer className={classes.tableContainer}>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />

                  {tableSets.columns.map(column => (
                    <TableCell key={column.dataKey}>{column.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableSets.data.map(({ rowName, rowData }, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      <div className={classes.thWrapper}>
                        <SpanRound style={{ borderColor: colors[idx].color }} />
                        <Typography variant="body2" align="center">
                          {rowName}
                        </Typography>
                      </div>
                    </TableCell>

                    {Object.entries(rowData).map(([key, value]) => (
                      <TableCell key={key}>{value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
    </Tab>
  );
};
