/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { OverviewAvgSpeedPanel } from './OverviewAvgSpeedPanel';

import { getAvgSpeed, getAvgSpeedIsLoading } from '../../state/selectors';
import { useFetchAvgSpeed, useAvgSpeedActions } from '../../state/hooks';

export const OverviewAvgSpeed = () => {
  const data = useSelector(getAvgSpeed);
  const isLoading = useSelector(getAvgSpeedIsLoading);
  const { toggleAvgSpeedPanel } = useAvgSpeedActions();

  useFetchAvgSpeed();

  return (
    <LoadingContainer loading={isLoading}>
      <OverviewAvgSpeedPanel data={data} onExpandToggle={toggleAvgSpeedPanel} />
    </LoadingContainer>
  );
};
