/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { OverviewParkedCallsPanel } from './OverviewParkedCallsPanel';

import { getParkedCalls, getParkedCallsIsLoading } from '../../state/selectors';
import { useFetchParkedCalls, useParkedCallsActions } from '../../state/hooks';

export const OverviewParkedCalls = () => {
  const data = useSelector(getParkedCalls);
  const isLoading = useSelector(getParkedCallsIsLoading);
  const isLoaded = useSelector(getParkedCallsIsLoading);
  const { toggleParkedCallsPanel, removeParkedCall } = useParkedCallsActions();
  const isMounted = useRef(false);
  const elId = 'OverviewParkedCallsPanel';

  useFetchParkedCalls(isMounted, elId);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  return (
    <>
      <div id={elId}></div>
      <LoadingContainer loading={isLoading && !isLoaded}>
        <OverviewParkedCallsPanel
          data={data}
          onExpandToggle={toggleParkedCallsPanel}
          removeParkedCall={removeParkedCall}
        />
      </LoadingContainer>
    </>
  );
};
