import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isElementVisible } from 'src/helpers/visibilityHelper';

import {
  getPage,
  getGroupId,
  getGroupListData,
  getAllFiltersStatuses,
  getFiltersIsExpanded,
  getFilters,
  getFiltersLead,
  getLeadFiltersIsExpanded,
  getFiltersStatuses,
  getReportingDateRange,
  getAvgSpeedDateRange,
} from './selectors';
import { actions } from './slice';
import { actions as actionsInsights } from '../../insights/state/slice';

export const useOverviewFiltersData = () => {
  const dispatch = useDispatch();
  const isExpanded = useSelector(getFiltersIsExpanded);
  const isLeadExpanded = useSelector(getLeadFiltersIsExpanded);
  const statuses = useSelector(getFiltersStatuses);
  const filters = useSelector(getFilters);
  const filtersLead = useSelector(getFiltersLead);
  const allStatuses = useSelector(getAllFiltersStatuses);
  const allGroups = useSelector(getGroupListData);

  const toggleFilterPanel = useCallback(() => dispatch(actions.toggleFilterPanel()), [dispatch]);
  const toggleLeadFilterPanel = useCallback(() => dispatch(actions.toggleLeadFilterPanel()), [dispatch]);
  const toggleStatuses = useCallback(() => dispatch(actions.toggleStatuses()), [dispatch]);
  const toggleStatus = useCallback(statusId => dispatch(actions.toggleStatus(statusId)), [dispatch]);
  const setFilterDate = useCallback(date => dispatch(actions.setFilterDate(date)), [dispatch]);
  const setFilterLeadDate = useCallback(date => dispatch(actions.setFilterLeadDate(date)), [dispatch]);
  const setFilterGroup = useCallback(groupdId => dispatch(actions.setFilterGroup(groupdId)), [dispatch]);
  const getOverviewData = useCallback(() => dispatch(actions.getOverviewData()), [dispatch]);
  const getOverviewLeadData = useCallback(() => dispatch(actions.getOverviewLeadData()), [dispatch]);

  return {
    isExpanded,
    isLeadExpanded,
    statuses,
    filters,
    filtersLead,
    allStatuses,
    allGroups,

    toggleFilterPanel,
    toggleLeadFilterPanel,
    toggleStatuses,
    toggleStatus,
    setFilterDate,
    setFilterLeadDate,
    setFilterGroup,
    getOverviewData,
    getOverviewLeadData,
  };
};

export const useReportingData = () => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(getReportingDateRange);

  const setReportingDate = useCallback(dateRange => dispatch(actions.setReportingDate(dateRange)), [dispatch]);

  return {
    from,
    to,

    setReportingDate,
  };
};

export const useAvgSpeedData = () => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(getAvgSpeedDateRange);

  const setAvgSpeedDate = useCallback(dateRange => dispatch(actions.setAvgSpeedDate(dateRange)), [dispatch]);

  return {
    from,
    to,

    setAvgSpeedDate,
  };
};

export const usePerformanceActions = () => {
  const dispatch = useDispatch();

  const togglePerformancePanel = useCallback(() => dispatch(actions.togglePerformancePanel()), [dispatch]);

  return {
    togglePerformancePanel,
  };
};

export const useFetchPerformance = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getPerformance({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// Source
export const useSourcesActions = () => {
  const dispatch = useDispatch();

  const toggleSourcesPanel = useCallback(() => dispatch(actions.toggleSourcesPanel()), [dispatch]);

  return {
    toggleSourcesPanel,
  };
};

export const useFetchSources = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getSources({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// Results
export const useResultsActions = () => {
  const dispatch = useDispatch();

  const toggleResultsPanel = useCallback(() => dispatch(actions.toggleResultsPanel()), [dispatch]);

  return {
    toggleResultsPanel,
  };
};

export const useFetchResults = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getResults({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// call activities
export const useCallActivitiesActions = () => {
  const dispatch = useDispatch();

  const toggleCallActivitiesPanel = useCallback(() => dispatch(actions.toggleCallActivitiesPanel()), [dispatch]);

  return {
    toggleCallActivitiesPanel,
  };
};

export const useFetchCallActivities = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getCallActivities({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// call upcoming calls
export const useUpcomingCallsActions = () => {
  const dispatch = useDispatch();

  const toggleUpcomingCallsPanel = useCallback(() => dispatch(actions.toggleUpcomingCallsPanel()), [dispatch]);

  return {
    toggleUpcomingCallsPanel,
  };
};

export const useFetchUpcomingCalls = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getUpcomingCalls({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// call avg speed
export const useAvgSpeedActions = () => {
  const dispatch = useDispatch();

  const toggleAvgSpeedPanel = useCallback(() => dispatch(actions.toggleAvgSpeedPanel()), [dispatch]);

  return {
    toggleAvgSpeedPanel,
  };
};

export const useFetchAvgSpeed = () => {
  const dispatch = useDispatch();
  const date = useSelector(getAvgSpeedDateRange);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getAvgSpeed({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// response
export const useResponseActions = () => {
  const dispatch = useDispatch();

  const toggleResponsePanel = useCallback(() => dispatch(actions.toggleResponsePanel()), [dispatch]);

  return {
    toggleResponsePanel,
  };
};

export const useFetchResponse = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getResponse({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// parked calls
export const useParkedCallsActions = () => {
  const dispatch = useDispatch();

  const toggleParkedCallsPanel = useCallback(() => dispatch(actions.toggleParkedCallsPanel()), [dispatch]);
  const removeParkedCall = useCallback(callId => dispatch(actions.removeParkedCall(callId)), [dispatch]);
  const getParkedCallsData = useCallback(() => dispatch(actions.getParkedCallsData()), [dispatch]);

  return {
    toggleParkedCallsPanel,
    removeParkedCall,
    getParkedCallsData,
  };
};

export const useFetchParkedCalls = (isMounted, elId) => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);
  const [timer, setTimer] = useState(false);
  const [interval, setInterval] = useState(1000);
  const [checkIsHidden, setCheckIsHidden] = useState(false);
  let timerId = null;

  timerId = setTimeout(() => {
    if (!isMounted || !isMounted.current) {
      clearTimeout(timerId);
      return;
    }

    if (document.hidden || !isElementVisible(elId)) {
      setInterval(1000);
      setCheckIsHidden(!checkIsHidden);
      return;
    }

    setInterval(5000);
    setTimer(!timer);
    clearTimeout(timerId);
  }, interval);

  useEffect(() => {
    dispatch(actions.getParkedCalls({ date, groupId }));
  }, [dispatch, date, groupId, timer]);

  useEffect(() => {
    dispatch(() => { });
  }, [dispatch, date, groupId, checkIsHidden]);
};

// active calls
export const useActiveCallsActions = () => {
  const dispatch = useDispatch();

  const toggleActiveCallsPanel = useCallback(() => dispatch(actions.toggleActiveCallsPanel()), [dispatch]);

  return {
    toggleActiveCallsPanel,
  };
};

export const useFetchActiveCalls = (isMounted, elId) => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);
  const [timer, setTimer] = useState(false);
  const [interval, setInterval] = useState(1000);
  const [checkIsHidden, setCheckIsHidden] = useState(false);
  let timerId = null;

  timerId = setTimeout(() => {
    if (!isMounted || !isMounted.current) {
      clearTimeout(timerId);
      return;
    }

    if (document.hidden || !isElementVisible(elId)) {
      setInterval(1000);
      setCheckIsHidden(!checkIsHidden);
      return;
    }

    setInterval(5000);
    setTimer(!timer);
    clearTimeout(timerId);
  }, interval);

  useEffect(() => {
    dispatch(actions.getActiveCalls({ date, groupId }));
  }, [dispatch, date, groupId, timer]);

  useEffect(() => {
    dispatch(() => { });
  }, [dispatch, date, groupId, checkIsHidden]);
};


export const useFetchFirstAttemptPerformance = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actionsInsights.getFirstAttemptPerformance({ date, groupId }));
  }, [dispatch, date, groupId]);
};