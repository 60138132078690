import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import {
  makeStyles,
  Button,
  Dialog,
  IconButton,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  OutlinedInput,
} from '@material-ui/core';

import TooltipedText from 'src/components/tooltipedText';
// import CloseIcon from 'src/assets/icons/close.svg';
import CloseIcon from '@material-ui/icons/Close';

import { useReportingData, useFetchPerformance } from './state/hooks';
import { getPerformance, getDateOverview, getDateGroup, getDateInsights } from './state/selectors';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    '& .MuiDialog-paper': {
      padding: theme.spacing(6, 9),
    },
  },
  header: {
    display: 'flex',
    marginBottom: 30,
  },
  titleContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  titleText: {
    fontSize: 22,
    fontFamily: 'Lato',
    fontWeight: 700,
  },
  tableHeader: {
    '&  th': {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: 16,
      border: 'unset',
    },
  },
  table: {
    minWidth: 600,
  },
  tableBody: {
    '& td': {
      borderBottom: '1px solid rgba(230, 230, 230, 0.7)',
      padding: theme.spacing(3),
      fontWeight: 700,
      fontSize: 16,
      backgroundColor: '#F6F6F6',
    },
    '& td:nth-child(1)': {
      maxWidth: 200,
      fontWeight: 400,
      fontSize: 14,
      width: 220,
    },
    '& td:nth-child(2)': {
      width: 155,
    },
    '& td:nth-child(3)': {
      backgroundColor: '#DAF1E6',
      width: 155,
    },
    '& tr:first-child': {
      '& td:first-child': {
        borderTopLeftRadius: 5,
      },
      '& td:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& tr:last-child': {
      '& td': {
        border: 'unset',
      },
      '& td:first-child': {
        borderBottomLeftRadius: 5,
      },
      '& td:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  tableFooter: {
    '&  th': {
      padding: theme.spacing(2, 3),
      fontWeight: 700,
      fontSize: 18,
      border: 'unset',
      color: theme.palette.primary.main,
    },
  },
  input: {
    width: 124,
    height: 42,
    // fontWeight: 700,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    '& input': {
      textAlign: 'center',
    },
  },
  textBold: {
    fontWeight: '700 !important',
  },
  positionSticky: {
    position: 'sticky',
    zIndex: 1000,
    color: '#fff',
    background: theme.palette.secondary.main,
    top: 0,
    '&:last-child': {
      background: '#38B778',
    },
  },
  highlightedRow: {
    '& > td': {
      color: '#fff',
      background: theme.palette.secondary.main,
      top: 0,
      fontWeight: 700,
      '&:first-child': {
        fontSize: 18,
        fontWeight: 700,
      },
      '&:last-child': {
        background: '#38B778',
        fontSize: 20,
      },
    }
  },
  closeIcon: {
    color: theme.palette.primary.main,
  }
}));

export default function ({ pageType }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ avgCloseRate: 25, avgLifetime: 10000 });

  const { setReportingDate } = useReportingData();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setState({ ...state, [event.currentTarget.name]: event.currentTarget.value });
  };

  const data = useSelector(getPerformance);
  const dateOverview = useSelector(getDateOverview);
  const dateGroup = useSelector(getDateGroup);
  const dateInsights = useSelector(getDateInsights);

  useEffect(() => {

    let dateRange;

    if (!open) {
      return;
    }

    switch (pageType) {
      case 'overview':
        dateRange = dateOverview;
        break;
      case 'group':
        dateRange = dateGroup;
        break;
      case 'insights':
        dateRange = dateInsights;
        break;
    }

    setReportingDate({ from: dateRange.from, to: dateRange.to });

  }, [pageType, open]);

  useFetchPerformance();

  return (
    data && data.dataSets && data.dataSets.length && (

      <>
        <div role="presentation" className={classes.buttonWrapper}>
          <Button variant="contained" color="primary" size="large" disableElevation onClick={handleOpen}>
            Attainable Revenue Calculator
          </Button>
        </div>

        <Dialog maxWidth="md" open={open} className={classes.dialog}>
          <div role="presentation" className={classes.header}>
            <div role="presentation" className={classes.titleContainer}>
              <TooltipedText tooltipText="Calculate your untapped revenue based on your current effort and results">
                <Typography className={classes.titleText} color="secondary">
                  Attainable Revenue Calculator
                </Typography>
              </TooltipedText>
            </div>

            <IconButton onClick={handleClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.positionSticky} />
                  <TableCell className={classes.positionSticky} align="center">Actual</TableCell>
                  <TableCell className={classes.positionSticky} align="center">Attainable</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                <TableRow>
                  <TableCell>Total number of leads for selected date range</TableCell>
                  <TableCell align="center">{data.totalSet.leadsPosted.value}</TableCell>
                  <TableCell align="center">{data.totalSet.leadsPosted.value}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Calls made to leads
                  </TableCell>
                  <TableCell align="center">{data.totalSet.leadsCalled.percent !== 'NaN' ? data.totalSet.leadsCalled.percent : 0}%</TableCell>
                  <TableCell align="center">100%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TooltipedText tooltipText="Percent of connections made from rep's total call attempts">
                      Aggregate connections
                    </TooltipedText>
                  </TableCell>
                  <TableCell align="center">{data.totalSet.leadsCalled.value ? Math.round(data.totalSet.callsConnected.value / data.totalSet.leadsCalled.value * 100) : 0}%</TableCell>
                  <TableCell align="center">{data.totalSet.leadsCalled.value ? Math.round(data.totalSet.callsConnected.value / data.totalSet.leadsCalled.value * 100) : 0}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total calls connected</TableCell>
                  <TableCell align="center">{data.totalSet.callsConnected.value}</TableCell>
                  <TableCell align="center">{data.totalSet.leadsCalled.value ? Math.ceil(data.totalSet.leadsPosted.value * (data.totalSet.callsConnected.value / data.totalSet.leadsCalled.value)) : 0}</TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '8px solid #fff', borderTop: '8px solid #fff' }}>
                  <TableCell style={{ fontWeight: 700 }}>Average close rate</TableCell>
                  <TableCell align="center" colSpan="2" style={{ padding: 18 }}>
                    <OutlinedInput
                      name="avgCloseRate"
                      defaultValue={state.avgCloseRate}
                      endAdornment={<div>%</div>}
                      className={classes.input}
                      onChange={handleChange}
                    />
                  </TableCell>
                  {/* <TableCell align="center">{state.avgCloseRate}%</TableCell> */}
                </TableRow>
                <TableRow className={classes.highlightedRow}>
                  <TableCell>Total new customers</TableCell>
                  <TableCell align="center">{Math.ceil((data.totalSet.callsConnected.value * state.avgCloseRate) / 100)}</TableCell>
                  <TableCell align="center">{data.totalSet.leadsCalled.value ? Math.ceil(((data.totalSet.leadsPosted.value * (data.totalSet.callsConnected.value / data.totalSet.leadsCalled.value)) * state.avgCloseRate) / 100) : 0}</TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '8px solid #fff', borderTop: '60px solid #fff' }}>
                  <TableCell className={classes.textBold} style={{ whiteSpace: 'nowrap' }}>Average revenue per customer</TableCell>
                  <TableCell align="center" colSpan="2" style={{ padding: 18 }}>
                    <OutlinedInput
                      name="avgLifetime"
                      defaultValue={state.avgLifetime}
                      startAdornment={<div>$</div>}
                      className={classes.input}
                      onChange={handleChange}
                    />
                  </TableCell>
                  {/* <TableCell align="center">${state.avgLifetime}</TableCell> */}
                </TableRow>
                <TableRow className={classes.highlightedRow}>
                  <TableCell className={classes.textBold}>Total revenue</TableCell>
                  <TableCell align="center">
                    {numeral(Math.ceil((data.totalSet.callsConnected.value * state.avgCloseRate) / 100) * state.avgLifetime).format('$0,0')}
                  </TableCell>
                  <TableCell align="center">
                    {numeral(data.totalSet.leadsCalled.value ? Math.ceil(((data.totalSet.leadsPosted.value * (data.totalSet.callsConnected.value / data.totalSet.leadsCalled.value)) * state.avgCloseRate) / 100) * state.avgLifetime : 0).format('$0,0')}
                  </TableCell>
                </TableRow>
              </TableBody>
              {/* <TableHead className={classes.tableFooter}>
              <TableRow>
                <TableCell>Total revenue</TableCell>
                <TableCell align="center">
                  {numeral(((75 * state.avgCloseRate) / 100) * state.avgLifetime).format('$0,0.00')}
                </TableCell>
                <TableCell align="center">$75,000</TableCell>
              </TableRow>
            </TableHead> */}
            </Table>
          </TableContainer>
        </Dialog>
      </>
    )
  );
}
