/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { makeStyles, Button, IconButton, Typography, Grid } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import OverviewImage from 'src/components/overviewImage';
import HelpWidget from 'src/components/helpWidget';

import { getUpcomingCalls, getUpcomingCallsIsLoading } from '../../state/selectors';
import { useFetchUpcomingCalls, useUpcomingCallsActions } from '../../state/hooks';

import pages from 'src/features/me/pages';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 0),
  },
  container: {
    display: 'flex',
    backgroundColor: '#BFDFEA',
    borderRadius: 5,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.05)',
    // padding: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      overflow: 'hidden',
    },
  },
  imageWrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      width: 103,
      top: -20,
      left: -50,
    },
  },
  main: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButtonWrapper: {
    height: '100%',
    padding: theme.spacing(1),
  },
  textWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      position: 'relative',
      zIndex: 100,
    },
  },
}));

export const OverviewRecommendation = () => {
  const classes = useStyles();

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              popup_title2 {
                html
                text
                raw
              }
              popup_text2 {
                html
                text
                raw
              }
              popup_button2 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const { tableSets } = useSelector(getUpcomingCalls);

  const [popup, setPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(null);
  const [isIncrease, setIsIncrease] = useState(null);
  const [open, setOpen] = useState(true);

  const handleExpand = () => {
    onExpandToggle();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setPopup(!popup);
  };

  useEffect(() => {
    setPopup(false);
  }, []);

  useEffect(() => {
    if (tableSets?.data[1].rowData.total > tableSets?.data[0].rowData.total) {
      setIsIncrease(true);
    } else {
      setIsIncrease(false);
    }
  }, [tableSets]);

  useEffect(() => {
    const helpPage = pages.find((item) => {
      return item.name === 'Help and Support';
    });
    setPage(helpPage);
  }, [pages]);

  return (
    open &&
    (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid container className={classes.main} spacing={2}>
            <Grid item xs={2} md={2} style={{ position: 'relative' }}>
              <div className={classes.imageWrapper}>
                <OverviewImage name="image2" />
              </div>
            </Grid>

            <Grid item xs={12} md={7}>
              <div className={classes.textWrapper}>
                <Typography variant="h4" paragraph>
                  Recommendation
                  {/* {prismicData.popup_title2.text} */}
                </Typography>

                {isIncrease &&
                  <Typography variant="body1">
                    Your call activity is projected to increase. Consider adding another rep soon or ask us about Live Agent to help you make more calls.
                    {/* {prismicData.popup_text2.text} */}
                  </Typography>
                }
                {!isIncrease &&
                  <Typography variant="body1">
                    Call activity is projected to go down. Take a look at your call rates to make sure there is no revenue leakage.
                    {/* {prismicData.popup_text2.text} */}
                  </Typography>
                }



              </div>
            </Grid>
            {isIncrease &&
              <Grid item xs={12} md={3}>
                {page && (page.popupList.length > 0) &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button color="primary" variant="contained" size="large"
                      onClick={(event) => {
                        setPopup(!popup);
                        setAnchorEl(event.currentTarget);
                      }}
                      disableElevation>
                      {prismicData.popup_button2.text}
                    </Button>
                    <HelpWidget
                      isOpen={popup}
                      anchorEl={anchorEl}
                      popupList={page.popupList}
                      handleClosePopup={handleClosePopup}
                    ></HelpWidget>
                  </div>
                }
              </Grid>
            }
            {!isIncrease &&
              <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button color="primary" variant="contained" size="large"
                  onClick={(event) => {
                    navigate('/me/insights/');
                  }}
                  disableElevation>
                  View Insights
                </Button>
              </Grid>
            }
          </Grid>
          <div className={classes.closeButtonWrapper}>
            <IconButton onClick={handleClose}>
              <CloseIcon color="secondary" />
            </IconButton>
          </div>
        </div>
      </div>
    )
  );
};
