/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Button, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import MaterialTable from 'src/components/materialTable';
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export const OverviewParkedCallsPanel = ({ data, onExpandToggle, removeParkedCall }) => {
  const classes = useStyles();

  const { isExpanded, columns, dataSets } = data;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title9 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const parkedCallsColumns = JSON.parse(JSON.stringify(columns));

  const parkedCallsData = JSON.parse(JSON.stringify(dataSets));

  parkedCallsData.forEach((item) => {
    if (item['phone']) {
      item['phone'] = phoneFormatter(item['phone']);
    }
    item['afterhours'] = item['afterhours'] ? 'Yes' : 'No';
  });

  const handleExpand = () => {
    onExpandToggle();
  };

  const handleRowDelete = parkedCall => {
    const { id } = parkedCall;

    return new Promise(resolve => {
      resolve(removeParkedCall(id));
    });
  };

  return (
    <>
      <Tab title={prismicData.title9.text} onClick={handleExpand} expanded={isExpanded} drag>
        {parkedCallsData.length > 0 ? (
          <MaterialTable
            columns={parkedCallsColumns}
            data={parkedCallsData}
            options={{
              headerStyle: {
                // paddingLeft: 35,
              },
              cellStyle: {
                // paddingLeft: 35,
              },
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            editable={{
              onRowDelete: handleRowDelete,
            }}
          />
        ) : (
          <Typography align="center" variant="body1">
            There is no data for selected date range
          </Typography>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={() => {
              navigate('/me/parked-calls/');
            }}
          >
            View all Parked Calls
          </Button>
        </div>
      </Tab>
    </>
  );
};
