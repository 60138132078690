import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Button, IconButton, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import OverviewImage from 'src/components/overviewImage';

import { getFirstAttemptPerformance, getFirstAttemptPerformanceIsLoading } from '../state/selectors';
import { useFetchFirstAttemptPerformance } from '../state/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    height: 121,
    display: 'flex',
    backgroundColor: '#b2eed5',
    borderRadius: 5,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.05)',
  },
  imageWrapper: {
    width: 180,
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButtonWrapper: {
    height: '100%',
    padding: theme.spacing(1),
  },
}));

export default function NewOpportunity() {
  const classes = useStyles();

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              popup_title1 {
                html
                text
                raw
              }
              popup_text1 {
                html
                text
                raw
              }
              popup_button1 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const { dataSets } = useSelector(getFirstAttemptPerformance);

  useFetchFirstAttemptPerformance();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const [leadCallRate] = dataSets.filter((item) => item.key === 'pressTwo');

    if (!leadCallRate) {
      return;
    }

    if (leadCallRate.percent[0] < 70) {
      setOpen(true);
    }
  }, [dataSets]);

  return (
    open && (
      <div className={classes.root}>
        <div className={classes.imageWrapper}>
          <OverviewImage name="image1" />
        </div>

        <div className={classes.main}>
          <div className={classes.textWrapper}>
            <Typography variant="h4" paragraph>
              {prismicData.popup_title1.text}
            </Typography>
            <Typography variant="body1">{prismicData.popup_text1.text}</Typography>
          </div>

          <div>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disableElevation
              onClick={() => {
                navigate('/me/insights/');
              }}>
              {prismicData.popup_button1.text}
            </Button>
          </div>
        </div>

        <div className={classes.closeButtonWrapper}>
          <IconButton onClick={handleClose}>
            <CloseIcon color="secondary" />
          </IconButton>
        </div>
      </div>
    )
  );
}
