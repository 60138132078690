/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { Doughnut, Bar } from 'react-chartjs-2';
// import zoomPlugin from 'chartjs-plugin-zoom';
// import { Chart as ChartJS } from 'chart.js';
// import 'chartjs-plugin-datalabels';
import { format, parse } from 'date-fns';

import Tab from 'src/components/tab';
import ChartLegend from 'src/components/chartLegend';
import ChartWrapper from 'src/components/chartWrapper';
import { coloredDatasets } from 'src/helpers/chartJsHelpers';
import { getWeekDayName } from 'src/helpers/time';

// ChartJS.pluginService.register(zoomPlugin);

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 125,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
    },
  },
  textWrapper: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  doughnutChartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  text: {
    marginTop: 25,
  },
}));

const doughnutChartOption = {
  // maintainAspectRatio: false,
  cutoutPercentage: 70,
  rotation: 0.5 * Math.PI,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  datalabels: {
    display: true,
    color: 'white'
  },
  legend: {
    display: false,
  },
  plugins: {
    labels: {
      render: 'percentage',
      precision: 0,
      position: 'outside',
      fontStyle: 'bold',
      textMargin: 5,
      outsidePadding: 4,
    },
  },
};

const weekDays = ['Monday', 'Tuesday', ' Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const lineChartOptions = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  responsive: true,
  plugins: {
    title: {
      display: true,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'x',
        // rangeMin: {
        //   // Format of min pan range depends on scale type
        //   x: 7,
        //   y: null
        // },
        // rangeMax: {
        //   // Format of max pan range depends on scale type
        //   x: 10,
        //   y: null
        // },
        // speed: 10,
      },
      // zoom: {
      //   pinch: {
      //     enabled: true       // Enable pinch zooming
      //   },
      //   wheel: {
      //     enabled: true       // Enable wheel zooming
      //   },
      //   mode: 'x',
      // }
    }
  },
  scales: {
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
          stepSize: 20,
          padding: 20,
          callback: (value, index, values) => {
            return `${value}%`;
          },
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        type: 'time',
        // distribution: 'series',
        stacked: false,
        bounds: 'ticks',
        time: {
          unit: 'day',
          tooltipFormat: 'DD. MMM',
          displayFormats: {
            day: 'DD. MMM yy',
          },
        },
        ticks: {
          padding: 20,
          // source: 'labels',
          beginAtZero: true,
          callback: value => {
            const date = parse(value, 'dd. MMM y', new Date());
            const weekDay = format(date, 'EEE');
            const displayValue = format(date, 'dd. MMM');

            return [weekDay, displayValue];
          },
        },
        gridLines: {
          display: false,
          // offsetGridLines: false,
        },
        categoryPercentage: 0.5,
        barPercentage: 0.4,
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    // callbacks: {
    //   label: (tooltipItem, data) => {
    //     return `${tooltipItem.yLabel}%`;
    //   },
    // },
  },
};

export const OverviewLeadResponsePanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, legends, secondaryDatasets, primaryDatasets } = data;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title8 {
                html
                text
                raw
              }
              titleTooltip8 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const doughnutChartData = { labels: legends, datasets: JSON.parse(JSON.stringify(secondaryDatasets)) };

  const lineChartData = coloredDatasets('bar', { datasets: JSON.parse(JSON.stringify(primaryDatasets)) });

  const handleExpand = () => {
    onExpandToggle();
  };

  return (
    <Tab
      title={prismicData.title8.text}
      tooltipedText={prismicData.titleTooltip8.text}
      onClick={handleExpand}
      expanded={isExpanded}
      drag
    >
      {
        secondaryDatasets?.[0]?.data?.length > 0 && primaryDatasets?.[0]?.data?.length > 0
          ? (<Grid container>
            <Grid item xs={12} md={4}>
              <div className={classes.doughnutChartWrapper}>
                <Doughnut data={doughnutChartData} options={doughnutChartOption} height={200} />
              </div>
            </Grid>

            <Grid item xs={12} md={8}>
              <ChartLegend legend={legends} maxWidth={600} />

              <ChartWrapper>
                <Bar options={lineChartOptions} data={lineChartData} height={300} />
              </ChartWrapper>
            </Grid>
          </Grid>)
          : (<Typography align="center" variant="body1">
            There is no data for selected date range
          </Typography>)
      }
    </Tab>
  );
};
