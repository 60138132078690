/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Grid, makeStyles, Button, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import InfoCard from 'src/components/infoCard';
import { formatCallTime } from 'src/helpers/time';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export const OverviewActiveCallsPanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();
  const { isExpanded, list: activeCalls } = data;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title10 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const handleExpand = () => {
    onExpandToggle();
  };

  return (
    <>
      <Tab title={prismicData.title10.text} onClick={handleExpand} expanded={isExpanded} drag>
        {(activeCalls && activeCalls.length > 0) ? (
          <Grid container spacing={2}>
            {activeCalls.map(({ id, source, initiated, lead, rep, groupName }) => (
              <Grid key={id} item xs={12} md={4}>
                <InfoCard source={source} lead={lead} rep={rep} time={initiated} groupName={groupName}/>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography align="center" variant="body1">
            There is no data for selected date range
          </Typography>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={() => {
              navigate('/me/active-calls/');
            }}
          >
            View all Active Calls
          </Button>
        </div>
      </Tab>


    </>
  );
};
